.Chart {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-basis: auto;
  user-select: none;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */

  /* Standard */
  .superscript {
    font-size: 0.7em;
  }
  svg {
    overflow: hidden;
  }

  .callout {
    background-color: white;
    border-radius: 10px;
    border: 2px solid rgb(84, 110, 110);

    .valueTitle {
      color: #2b2b55;
      font-weight: bold;
    }

    .calloutContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 100%;
      font-size: 50px;
      font-family: 'Roboto';
      pointer-events: all;

      .calloutData {
        display: flex;
        padding: 0 50px;
        height: 100%;
        width: 100%;
        flex-direction: column;
        justify-content: space-around;
      }

      .closeButtonContainer {
        display: flex;
        justify-content: flex-end;

        :hover {
          background: rgb(84, 110, 110);
          color: white;
          height: 100%;
        }

        .closeButton {
          display: block;
          margin: auto;
          font-size: 100px;
        }
      }
    }
  }

  text-anchor: start;
  touch-action: none;
}
